<template>
<modal
    v-bind="modalData"
    @close="close"
    class="modal-bg-gray"
  >
  <page :data="pageData" :is-child="true" @refresh="refresh">
    <template #cmsleftcomponents>
      <div class="space-y-4" v-if="layout">
          <card>
            <PackageItemHeader :data="layout.Package" />
          </card>
          <template v-if="isVA">
            <card>
              <list-item :data="layout.ChangeAddress" @title-action-click="handleAction"></list-item>
              <list-item :data="{ ...layout.DeliveryAddress, ...getStateData('address', 'Addresses')}"></list-item>
            </card>
            <card>
              <list-item :data="layout.SelfDeclare" @title-action-click="handleAction">
              <template #text v-if="hasSelfDeclaredItem">
                <div class="w-full">
                  <list-item :data="{ Text: 'Nama Barang', RightText: items[0].SelfDeclareItem, BorderWidth: 0 }"></list-item>
                  <list-item :data="{ Text: 'Harga Barang', RightText: items[0].AmountGoodValue, BorderWidth: 0 }">
                    <template #righttext>
                      {{ items[0].AmountGoodValue | currency(user.CurrencyObj) }}
                    </template>
                  </list-item>
                  <list-item :data="{ Text: 'Kategori Paket', RightText: shippingGoodTypeDescription, BorderWidth: 0 }"></list-item>
                </div>
              </template>
              </list-item>
            </card>
            <card>
              <list-item :data="layout.Note">
                 <template #text>
                  <b-input type="textarea" :placeholder="layout.Note.Text" v-model="note" class="w-full"></b-input>
                </template>
              </list-item>
            </card>
            <card v-if="layout.Gallery">
              <list-item :data="{
                Title: layout.Gallery.Title,
                BorderWidth: 0
              }"></list-item>
              <ImageList :data="layout.Gallery.Data" />
            </card>
            <card v-if="layout.File" >
              <list-item :data="{
                Title: layout.File.Title,
                BorderWidth: 0
              }"></list-item>
              <FileList :data="layout.File.Data" />
            </card>
            <card>
              <list-item :data="layout.Amounts"></list-item>
              <!-- <list-item v-else-if="d.Id === 'subtotalamount'" :data="d.Data">
        <template #righttext>{{config.TotalAmount | currency(user.CurrencyObj) }}</template> -->
      <!-- </list-item> -->
              <list-item :data="layout.InternationalAmount">
                <template #righttext>{{(amounts.AmountInternational || 0) | currency(user.CurrencyObj) }}</template>
              </list-item>
              <list-item :data="layout.DomesticAmount" >
                <template #righttext>{{(amounts.AmountDomestic || 0) | currency(user.CurrencyObj) }}</template>
              </list-item>
              <list-item v-if="layout.AmountAdditional1" :data="layout.AmountAdditional1" >
                <template #righttext>{{layout.AmountAdditional1.RightText | currency(user.CurrencyObj)}}</template>
              </list-item>
              <list-item v-if="layout.AmountAdditional2" :data="layout.AmountAdditional2" >
                  <template #righttext>{{layout.AmountAdditional2.RightText | currency(user.CurrencyObj)}}</template>
              </list-item>
              <list-item v-if="layout.AmountAdditional3" :data="layout.AmountAdditional3" >
                  <template #righttext>{{layout.AmountAdditional3.RightText | currency(user.CurrencyObj)}}</template>
              </list-item>
              <list-item :data="{...layout.RepackingAmount, Class: !repacking ? 'hidden' : ''}">
                <template #righttext>{{(amounts.AmountRepacking || 0) | currency(user.CurrencyObj) }}</template>
              </list-item>
              <list-item :data="{...layout.InsuranceAmount, Class: !insurance ? 'hidden' : ''}">
                <template #righttext>{{(insuranceAmount || 0) | currency(user.CurrencyObj) }}</template>
              </list-item>
              <list-item :data="layout.TaxAmount">
                <template #righttext>
                  {{amounts.TotalTax | currency(user.CurrencyObj) }}
                </template>
                <template #footer>
                  <div>
                    <span v-if="amounts.AmountBM">BM: {{amounts.AmountBM | currency(user.CurrencyObj) }}</span>
                    <span v-if="amounts.AmountBM && amounts.AmountPPN">, </span>
                    <span v-if="amounts.AmountPPN">PPN: {{amounts.AmountPPN | currency(user.CurrencyObj) }}</span>
                    <span v-if="amounts.AmountPPN && amounts.AmountPPH">, </span>
                    <span v-if="amounts.AmountPPH">PPH: {{amounts.AmountPPH | currency(user.CurrencyObj) }}</span>
                  </div>
                </template>
              </list-item>
              <list-item :data="{...layout.VoucherAmount, Class: canApplyVoucher ? 'hidden' : ''}">
                <template #righttext>{{(voucherAmount || 0) | currency(user.CurrencyObj) }}</template>
              </list-item>
              <list-item :data="layout.TotalAmount">
                <template #righttitle><span class="font-bold">{{totalAmount | currency(user.CurrencyObj) }}</span></template>
              </list-item>
            </card>

            <card>
              <list-item :data="layout.Voucher">
                <template #text>
                  <div class="w-full">
                    <div class="flex items-center w-full">
                      <strong class="flex-1">{{layout.Voucher.Text}}</strong>
                      <div v-if="canApplyVoucher">
                        <b-input size="is-small" placeholder="Kode Voucher" v-model="voucher"/>
                      </div>
                      <div v-else>
                        <strong>{{ voucherCode }}</strong>
                      </div>
                    </div>
                    <div class="flex justify-end">
                        <b-button v-if="canApplyVoucher" type="is-primary" class="mt-1" size="is-small" @click="applyVoucher">Submit</b-button>
                        <b-button v-else type="is-link-danger" @click="removeVoucher">Hapus</b-button>
                    </div>
                  </div>
                </template>
              </list-item>
            </card>
          </template>
          <template v-else>
            <card v-if="layout.RecommendedMerchant">
              <list-item :data="layout.RecommendedMerchant"></list-item>
            </card>
            <card>
              <list-item :data="layout.ItemDetail"></list-item>
              <list-item v-for="(data, key) in layout.ItemDetails" :data="data" :key="key">
                <template #righttext>
                  {{data.RightText | currency(user.CurrencyObj)}}
                </template>
              </list-item>
             <list-item v-if="layout.AmountAdditional1" :data="layout.AmountAdditional1" >
                <template #righttext>{{layout.AmountAdditional1.RightText | currency(user.CurrencyObj)}}</template>
              </list-item>
              <list-item v-if="layout.AmountAdditional2" :data="layout.AmountAdditional2" >
                  <template #righttext>{{layout.AmountAdditional2.RightText | currency(user.CurrencyObj)}}</template>
              </list-item>
              <list-item v-if="layout.AmountAdditional3" :data="layout.AmountAdditional3" >
                  <template #righttext>{{layout.AmountAdditional3.RightText | currency(user.CurrencyObj)}}</template>
              </list-item>
            </card>
          </template>
          <list-item  :data="layout.PaymentMethod">
            <template #text>
              <div class="flex flex-col md:flex-row items-start md:items-center">
                <div class="flex items-center">
                  <div :class="['rounded-md p-1 border mr-2 flex justify-center cursor-pointer', { 'border-gray-400': getStateData('paymentMethod').Oid !== method.Oid, 'border-red-400': getStateData('paymentMethod').Oid === method.Oid }]" style="width: 55px; height: auto" v-for="(method, i) in config.PaymentMethods" :key="i" @click="selectPaymentMethod(method)">
                    <img :src="method.Image1" style="width: inherit; height: inherit" class="object-contain" />
                  </div>
                </div>
              {{ layout.PaymentMethod.Text }}
              </div>
            </template>
        </list-item>
        <list-item :data="getPayButtonData(layout.PayButton)" @action-click="pay"></list-item>
      </div>
    </template>
    <!-- <template #cmslefttype(vaddress-listitem)="{ data: d }">
      <list-item v-if="d.Id === 'contactname'" :data="d.Data">
        <template #text>
          <input type="text" :placeholder="d.Data.Text" v-model="name" ref="inputname" :class="[{'border-b border-red-500': !name}, 'w-full']"/>
        </template>
        <template #footer>
          <div>
            <span class="text-sm font-normal text-red-500" v-if="!name">Name is required</span>
          </div>
        </template>
      </list-item>
      <list-item v-else-if="d.Id === 'deliveryaddress'" :data="{...d.Data, ...getStateData('address', 'Addresses')}"></list-item>
      <list-item v-else-if="d.Id === 'paymentmethod'" :data="d.Data">
        <template #text>
          <div class="flex items-center">
            <div :class="['rounded border mr-2 flex justify-center cursor-pointer', { 'border-gray-400': getStateData('paymentMethod').Oid !== method.Oid, 'border-red-400': getStateData('paymentMethod').Oid === method.Oid }]" style="width: 100px; height: 50px" v-for="(method, i) in config.PaymentMethods" :key="i" @click="selectPaymentMethod(method)">
              <img :src="method.Image1" style="width: inherit; height: inherit" class="object-contain" />
            </div>
            {{ d.Data.Text }}
          </div>
        </template>
      </list-item>
      <list-item v-else-if="d.Id === 'deliverymethod'" :data="{...d.Data, ...getStateData('deliveryMethod', 'DeliveryMethods')}"></list-item>

      <list-item v-else-if="d.Id === 'Note'" :data="d.Data">
        <template #text>
          <b-input type="textarea" :placeholder="d.Data.Text" v-model="note" class="w-full"></b-input>
        </template>
      </list-item>
      <list-item v-else-if="d.Id === 'totalamount'" :data="d.Data">
        <template #righttitle>{{totalAmount | currency(user.CurrencyObj) }}</template>
      </list-item>
      <list-item v-else-if="d.Id === 'repacking'" :data="d.Data" @select="v => repacking = v"></list-item>
      <list-item v-else-if="d.Id === 'paybutton'" :data="getPayButtonData(d.Data)" @action-click="pay"></list-item>
      <list-item v-else-if="d.Id === 'repackingoptions'" :data="{ ...d.Data, Class: !repacking ? 'hidden' : '' }">
        <template #text>
          <b-radio
            v-for="(o) in config.RepackingPrices"
            :key="o.Oid"
            v-model="repackingPrice"
            type="is-primary"
            name="repackingPrice"
            :native-value="o.Oid">
                {{o.Name}}
            </b-radio>
        </template>
      </list-item>
      <list-item v-else-if="d.Id === 'insurance'" :data="d.Data" @select="v => insurance = v"></list-item>
      <list-item v-else-if="d.Id === 'voucher'" :data="d.Data">
        <template #righttext>
          <div v-if="canApplyVoucher">
            <b-input placeholder="Kode Voucher" v-model="voucher"/>
            <div class="text-right">
              <b-button type="is-primary" class="mt-1" @click="applyVoucher">Submit</b-button>
            </div>
          </div>
          <div v-else>
            <strong>{{ voucherCode }}</strong><br/>
            <b-button type="is-link-danger" @click="removeVoucher">Hapus</b-button>
          </div>
        </template>
      </list-item>
      <list-item v-else-if="d.Id === 'selfdeclare'" :data="{ ...d.Data, TitleActions: [ { ...d.Data.TitleActions[0], Text: hasSelfDeclaredItem ? 'Ubah' : 'Tambah' } ] }" @title-action-click="handleAction">
        <template #text v-if="hasSelfDeclaredItem">
          <div class="w-full">
            <list-item :data="{ Text: 'Nama Barang', RightText: items[0].Description, BorderWidth: 0 }"></list-item>
            <list-item :data="{ Text: 'Harga Barang', RightText: items[0].AmountGoodValue, BorderWidth: 0 }"></list-item>
            <list-item :data="{ Text: 'Kategori Paket', RightText: items[0].ShippingGoodType, BorderWidth: 0 }"></list-item>
          </div>
        </template>
      </list-item>
      <list-item v-else :data="d.Data" @title-action-click="handleAction"></list-item>
    </template>

    <template #cmslefttype(vaddress-order-liveitem)="{ data: d }">
      <live-item :data="d" v-model="d.Data" @change="handleItemChange" @blur="handleItemBlur"></live-item>
    </template> -->

    <template #aftercmsleftcomponents>
      <address-modal v-bind="modalProps('address')" @close="closeModal('address')" @select="data => selectOption(data, 'address')"></address-modal>
      <modal-cms-page v-bind="modalProps('options')" @close="closeModal('options')">
        <template #cmslefttype(vaddress-listitem)="{ data: d }">
          <list-item
            :data="d.Data"
            @click="selectOption(d.Data, 'options')"></list-item>
        </template>
      </modal-cms-page>
      <self-declare-modal
        v-bind="modalProps('selfdeclare')"
        @close="closeModal('selfdeclare')"
        :shipping-good-type-options="config.ShippingGoodTypes"
        :initial-values="initialSelfDeclareValues"
        @submit="submitSelfDeclare"
      ></self-declare-modal>
    </template>
  </page>
  </modal>
</template>
<script>
import moment from 'moment'
import ModalCmsPage from '../../base/ModalCMSPage'
import Card from '@/components/vaddress/common/Card'
import PackageItemHeader from '@/components/vaddress/order/PackageItemHeader'
import stripe from '../../../../libs/stripe'
import { formatCurrency } from '@/filters/number'
export default {
  extends: ModalCmsPage,
  components: {
    ListItem: () => import('@/components/vaddress/ListItem'),
    ImageList: () => import('@/components/vaddress/order/ImageList'),
    FileList: () => import('@/components/vaddress/order/FileList'),
    AddressModal: () => import('../AddressModal.vue'),
    ModalCmsPage,
    SelfDeclareModal: () => import('./SelfDeclareModal.vue'),
    PackageItemHeader,
    Card
  },
  data () {
    return {
      name: '',
      note: '',
      repacking: false,
      repackingPrice: null,
      amounts: {},
      insurance: false,
      voucher: '',
      voucherFormApplied: false,
      submitted: false
    }
  },
  methods: {
    pay () {
      this.$buefy.dialog.confirm({
          message: this.config.SubmitPaymentMessage,
          type: 'is-primary',
          onConfirm: async () => {
            const loading = this.$buefy.loading.open()
            try {
              const paymentMethod = this.$store.state.order.paymentMethod
              if (paymentMethod.IsCreditCardStripe) {
                await this.payStripe()
              }
              const pos = await this.submit()

              this.$store.commit('order/SET_PAYMENT_METHOD', null)
              this.$store.commit('order/SET_ADDRESS', null)
              this.$store.commit('order/SET_DELIVERY_METHOD', null)
              this.submitted = true
              await this.close()

              if (paymentMethod.IsTransfer) {
                this.$router.replace('/user/checkout/banktransfer?Oid=' + pos.Oid)
              } else if (paymentMethod.IsVA) {
                this.$router.replace('/user/checkout/va?Oid=' + pos.Oid)
              } else {
                this.$router.replace('/user/orders/inprocess')
              }
            } catch (err) {
              alert(err.message)
            } finally {
              loading.close()
            }
          }
      })
    },
    payStripe () {
      return stripe.checkout({
        id: this.oids.join('_'),
        v: '1234',
        tokenUrl: '/payment/cc',
        paymentUrl: '/payment/cc_payment',
        data: {
          amount: this.totalAmount
        }
      })
    },
    async submit () {
      return this.$baseApi.post('/user/checkout', {
        Oids: this.oids,
        ContactName: this.name,
        Note: this.note,
        PaymentMethod: this.$store.state.order.paymentMethod.Oid,
        POSUserAddress: this.config && this.config.Items[0].TransactionType !== 'Jastip' ? this.$store.state.order.address && this.$store.state.order.address.Oid : null,
        // DeliveryMethod: this.$store.state.order.deliveryMethod.Oid,
        Repacking: this.repacking,
        AmountRepacking: this.amounts.AmountRepacking,
        AmountInsurance: this.insuranceAmount,
        RepackingPrice: this.repackingPrice,
        ToShippingSubDistrict: this.config && this.config.Items[0].TransactionType !== 'Jastip' ? this.$store.state.order.SubDistrict : null,
        DomesticShippingPrice: this.amounts.AmountDomestic,
        TaxAmount: this.amounts.TotalTax,
        SubtotalAmount: this.amounts.AmountInternational,
        Details: this.items,
        TotalAmount: this.totalAmount
      })
    },
    async applyVoucher () {
      if (!this.voucher) return alert('Kode voucher perlu diisi')
      if (!this.hasSelfDeclaredItem) return alert('Mohon isi detail barang terlebih dahulu')
      const loading = this.$buefy.loading.open()
      try {
        const oid = this.oids[0]
        const response = await this.$baseApi.post(`/user/applyvoucher?Oid=${oid}`, {
          Date: moment().format('YYYY-MM-DD'),
          Code: this.voucher,
          Action: 'Apply',
          TotalAmount: this.totalAmount
        })
        this.updateItemValue({ AmountVoucher: response })
      } catch (err) {
        alert(err.message)
      } finally {
        loading.close()
      }
    },
    async removeVoucher () {
      const loading = this.$buefy.loading.open()
      try {
        const oid = this.oids[0]
        await this.$baseApi.post(`/user/applyvoucher?Oid=${oid}`, {
          Code: this.voucherCode,
          Action: 'Remove'
        })
        this.updateItemValue({ AmountVoucher: 0 })
      } catch (err) {
        alert(err.message)
      } finally {
        loading.close()
      }
    },
    async submitSelfDeclare (values) {
      this.updateItemValue(values)
      await this.getInternationalAmount()
      await this.getTaxAmount()
      if (this.voucherCode) {
        await this.removeVoucher()
        await this.applyVoucher()
      }
    },
    updateItemValue (values) {
      console.log(this.apiPath, values)
      this.$store.dispatch('webcontent/set', { path: this.apiPath, response: { ...this.response, Config: { ...this.config, Items: [{ ...this.config.Items[0], ...values }] } } })
    },
    handleAction ({ action }) {
      if (action.Id === 'changeaddress') {
        this.openModal('address', {
          url: '/user/checkout/addresses'
        })
      } else if (action.Id === 'changedeliverymethod') {
        this.openModal('options', {
          url: '/user/checkout/deliverymethods'
        })
      } else if (action.Id === 'changepaymentmethod') {
        this.openModal('options', {
          url: '/user/checkout/paymentmethods'
        })
      } else if (action.Id === 'selfdeclare') {
        this.openModal('selfdeclare', {
        })
      }
    },
    handleItemChange (k, v, d) {
      if (k === 'ShippingGoodType') {
        this.getInternationalAmount()
        this.$nextTick(() => {
          if (this.canCalculateTax) this.getTaxAmount()
        })
      }
    },
    handleItemBlur (k, v, d) {
      this.$nextTick(() => {
        if (k === 'AmountGoodValue' && this.canCalculateTax) {
          this.getTaxAmount()
        }
      })
    },
    setAmounts (value) {
      this.$set(this, 'amounts', { ...this.amounts, ...value })
    },
    selectOption (data, modalKey) {
      const url = this.modals.pop().url
      if (data.Oid) {
        if (url.indexOf('paymentmethods') !== -1) {
          this.selectPaymentMethod(data)
        } else if (url.indexOf('address') !== -1) {
          const { FooterActions, ...address } = data
          this.selectAddress(address)
        } else {
          this.$store.commit('order/SET_DELIVERY_METHOD', data)
        }
        this.closeModal(modalKey)
      }
    },
    selectPaymentMethod (d) {
      this.$store.commit('order/SET_PAYMENT_METHOD', d)
    },
    selectAddress (d) {
      this.$store.commit('order/SET_ADDRESS', d)
    },
    getStateData (type, ref) {
      const val = this.$store.state.order[type]
      if (val) {
        if (type === 'address') {
          return { ...val, Actions: null }
        }
        return val
      }
      return {}
    },
    getPayButtonData (d) {
      return {
        ...d,
        Actions: [{
          ...d.Actions[0],
          Class: 'w-full',
          Text: 'Bayar ' + formatCurrency(this.totalAmount, this.user.CurrencyObj),
          Disabled: this.payButtonDisabled
        }]
      }
    },
    afterRequestCMSData () {
      this.$nextTick(() => {
        if (this.$refs.inputname) this.$refs.inputname.focus()

        if (this.config.DefaultAddress) this.$store.commit('order/SET_ADDRESS', this.config.DefaultAddress)
        if (this.config.DefaultPaymentMethod) this.selectPaymentMethod(this.config.DefaultPaymentMethod)
      })
    },
    async getInternationalAmount (d) {
      const loading = this.$buefy.loading.open()
      try {
        const d = this.items[0]
        console.log(d)
        const res = await this.$baseApi.post('cost/international', {
          Warehouse: d.Warehouse,
          QuantityVolume: d.QuantityVolume,
          QuantityWeight: d.QuantityWeight,
          ShippingGoodType: d.ShippingGoodType
        })
        this.setAmounts(res)
      } catch (err) {
        alert(err.message)
      } finally {
        loading.close()
      }
    },
    async getTaxAmount () {
      const loading = this.$buefy.loading.open()
      try {
        const d = this.items[0]
        const res = await this.$baseApi.post('cost/tax', {
          AmountGoodValue: d.AmountGoodValue,
          ShippingGoodType: d.ShippingGoodType,
          ToShippingSubDistrict: this.$store.state.order.address.SubDistrict
        })
        this.setAmounts(res)
      } catch (err) {
        alert(err.message)
      } finally {
        loading.close()
      }
    },
    async getRepackingAmount () {
      const loading = this.$buefy.loading.open()
      try {
        const d = this.items[0]
        const res = await this.$baseApi.post('cost/repacking', {
          VirtualAddressRepackingPrice: this.repackingPrice,
          QuantityVolume: d.QuantityVolume,
          VirtualAddressPackage: this.oids[0]
        })
        console.log(res)
        this.setAmounts(res)
      } catch (err) {
        alert(err.message)
      } finally {
        loading.close()
      }
    },
    async getDomesticAmount () {
      const loading = this.$buefy.loading.open()
      try {
        const d = this.items[0]
        const res = await this.$baseApi.post('cost/domestic', {
          ToShippingSubDistrict: this.$store.state.order.address.SubDistrict,
          QuantityVolume: d.QuantityVolume,
          QuantityWeight: d.QuantityWeight,
          IsSelfPickup: this.$store.state.order.address.IsSelfPickup
        })
        this.setAmounts(res)
      } catch (err) {
        alert(err.message)
      } finally {
        loading.close()
      }
    },
    async close () {
      if (this.voucherCode && !this.submitted) {
        await this.removeVoucher()
      }
      Object.assign(this, {
        name: '',
        note: '',
        repacking: false,
        repackingPrice: null,
        amounts: {},
        insurance: false,
        voucher: '',
        voucherFormApplied: false,
        submitted: false
      })
      this.$store.dispatch('webcontent/get', { path: '/user/orders' })
      this.$emit('close')
    }
  },
  computed: {
    payButtonDisabled () {
      const {
        address,
        // deliveryMethod,
        paymentMethod
      } = this.$store.state.order
      if (!this.isVA) return !paymentMethod

      return !address ||
      !paymentMethod ||
      // !deliveryMethod ||
      !this.totalAmount
    },
    oids () {
        return this.config.Oids
    },
    hasSelfDeclaredItem () {
      console.log(this.items)
      return this.items[0].SelfDeclareItem || this.items[0].FeaturedFile
    },
    initialSelfDeclareValues () {
      const item = this.items[0]
      return {
        SelfDeclareItem: item.SelfDeclareItem,
        AmountGoodValue: item.AmountGoodValue,
        ShippingGoodType: item.ShippingGoodType,
        FeaturedFile: null
      }
    },
    canApplyVoucher () {
      return this.items[0].AmountVoucher <= 0 && !this.items[0].POSVoucher
    },
    voucherCode () {
      return this.voucher || (this.items[0].POSVoucherObj && this.items[0].POSVoucherObj.Code)
    },
    insuranceAmount () {
      const d = this.items[0]

      if (!this.insurance) return 0

      const amt = (d.AmountGoodValue * 1) / 100
      console.log(d.AmountGoodValue)
      if (amt < 30000) return 30000
      return amt
    },
    totalAmount () {
      if (this.config.TotalAmount) return this.config.TotalAmount

      const { AmountInternational, TotalTax, AmountRepacking, AmountDomestic } = this.amounts
      if (this.config && this.config.Items[0].SelfDeclareBackend && AmountInternational === undefined) return this.getInternationalAmount()

      if (!AmountInternational && !TotalTax && !AmountDomestic) return 0

      if (this.repacking && !AmountRepacking) return 0

      return AmountInternational + (AmountRepacking || 0) + TotalTax + AmountDomestic + this.insuranceAmount + this.totalAdditionalAmount - (this.voucherAmount || 0)
    },
    totalAdditionalAmount () {
      return this.config.AmountAdditional.reduce((amount, value) => amount + Number(value), 0)
    },
    items () {
      return this.config.Items
      .map((d) => ({
        ...d,
        Warehouse: d.WarehouseObj ? d.WarehouseObj.Oid : null,
        AmountBM: this.amounts.AmountBM,
        AmountPPH: this.amounts.AmountPPH,
        AmountPPN: this.amounts.AmountPPN,
        AmountRepacking: this.amounts.AmountRepacking,
        VirtualAddressRepackingPrice: this.repackingPrice,
        TotalTax: this.amounts.TotalTax
      }))
    },
    canCalculateTax () {
      const d = this.items[0]
      return d.AmountGoodValue && d.ShippingGoodType && this.$store.state.order.address.SubDistrict
    },
    item () {
      return this.items[0]
    },
    shippingGoodTypeDescription () {
      if (this.item.ShippingGoodType) {
        const shippingGoodType = this.config.ShippingGoodTypes.find(shippingGoodType => shippingGoodType.Oid === this.item.ShippingGoodType)
        if (shippingGoodType) return shippingGoodType.Name
      }
      return ''
    },
    isVA () {
      return !this.config.TransactionType || this.config.TransactionType === 'Ongkir'
    },
    voucherAmount () {
      console.log(this.items[0].AmountVoucher)
      return this.items[0].AmountVoucher
    },
    layout () {
      return this.config.Layout
    }
  },
  watch: {
    repacking (v) {
      if (!v) {
        this.repackingPrice = null
      } else {
        this.repackingPrice = this.config.RepackingPrices[0].Oid
      }
    },
    repackingPrice (v, o) {
      if (v) {
        if (this.items[0].ShippingGoodType) {
          this.getRepackingAmount()
        }
      } else {
        this.amounts.AmountRepacking = 0
      }
    },
    '$store.state.order.address' (v) {
      if (v && this.isVA) {
        this.getDomesticAmount()
        if (this.canCalculateTax) this.getTaxAmount()
      }
    }
  }
}
</script>

<style>

</style>
