<template>
  <page :data="pageData" :is-child="_isChild" @refresh="refresh">
    <template #cmslefttype(vaddress-order-packageitem)="{ data: d }">
      <package-item
        :data="d.Data"
        :selected="selected.findIndex(i => i.Oid === d.Data.Oid) !== -1"
        @click="show(d.Data)"
        @select="select(d.Data)">
      </package-item>
    </template>

    <!-- <template #cmslefttype(vaddress-listitem)="{ data: d }">
      <list-item
        :data="d.Data"
        @title-click="showHistory(d.Data)"
        @click="showHistory(d.Data)">
      </list-item>
    </template> -->

    <template #aftercmsleftcomponents>
      <div v-if="config.EnableConsolidate" id="checkoutbutton" class="p-4 bg-white border-t border-gray-400 flex" :style="getCheckoutbuttonStyles()">
        <div class="flex-1">
          <span class="font-bold">{{totalAmount | currency(user.CurrencyObj)}}</span><br/>
          <span class="text-red-500" v-if="errorWarehouse">{{config.MultipleWarehouseErrorMessage}}</span>
        </div>
        <b-button :disabled="!selected.length || errorWarehouse" type="is-primary" @click="click">{{ buttonText }}</b-button>
      </div>
      <!-- <modal-cms-page v-bind="modalProps('package')" @close="closeModal('package')"></modal-cms-page> -->
      <checkout-modal v-bind="modalProps('package')" @close="closePackageModal"></checkout-modal>
      <history-modal modal-key="history"></history-modal>
      <!-- <modal-cms-page v-bind="modalProps('history')" @close="closeModal('history')">
        <template #cmslefttype(vaddress-order-liveitem)="{ data: d}">
          <live-item
            :data="d"
            @click="showHistoryDetail(d.Data)">
          </live-item>
        </template>
        <template #aftercmsleftcomponents>
          <modal-cms-page v-bind="modalProps('historydetail')" @close="closeModal('historydetail')">
          </modal-cms-page>
        </template>
      </modal-cms-page> -->
    </template>
  </page>
</template>

<script>
// import ModalCmsPage from '../../base/ModalCMSPage'
import CheckoutModal from './CheckoutModal'
import CMSPage from '../../base/CMSPage'
export default {
  extends: CMSPage,
  components: {
    PackageItem: () => import('@/components/vaddress/order/PackageItem'),
    // LiveItem: () => import('@/components/vaddress/order/LiveItem'),
    // ModalCmsPage,
    HistoryModal: () => import('./HistoryModal'),
    CheckoutModal
  },
  data () {
    return {
      selected: [],
      selectedWarehouse: null
    }
  },
  mounted () {
    if (this.user.IsLogin) {
      this.$store.dispatch('webcontent/get', { path: '/user/orders' })
    } else {
      if (window.history.length > 1) return this.$router.replace('/user/dashboard')
      this.$router.push('/login')
    }
  },
  methods: {
    select (v) {
      const index = this.selected.findIndex(i => v.Oid === i.Oid)
      if (index !== -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(v)
      }
    },
    show (d) {
      // if (!this.EnableConsolidate) {
      //   this.click()
      // } else {
      //   this.openModal('package', {
      //     url: '/user/orders/package?Oid=' + d.Oid
      //   })
      // }
      if (d.Type === 'order') {
        this.showHistory(d)
      } else {
        this.$nextTick(() => {
          this.openModal('package', {
            url: '/user/checkout?Oid[]=' + d.Oid
          })
        })
      }
    },
    showHistory (d) {
      this.openModal('history', {
        url: '/user/orders/history?Oid=' + d.Oid
      })
    },
    showHistoryDetail (d) {
      this.openModal('historydetail', {
        url: '/user/orders/package?Oid=' + d.Oid
      })
    },
    click () {
      this.$router.push({ path: '/user/checkout', query: { 'Oid[]': this.selected.map((i) => i.Oid) } })
    },
    afterRequestCMSData () {
      if (this.isSm && this.config.EnableConsolidate) {
        const content = document.getElementById('orderlayoutcmsright')
        const button = document.getElementById('checkoutbutton')

        content.style.marginBottom = `calc(${button.offsetHeight}px + 1rem)`
      }
    },
    getCheckoutbuttonStyles () {
      if (this.isSm) {
        return {
          position: 'fixed',
          bottom: 'calc(1rem + 24px)',
          left: 0,
          right: 0
        }
      } else {
        return {
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10000
        }
      }
    },
    closePackageModal () {
      this.closeModal('package')
      this.init()
    }
  },
  computed: {
    totalAmount () {
      return this.selected.reduce((p, v) => {
        p += v.TotalAmount
        return p
      }, 0)
    },
    buttonText () {
      const { length } = this.selected
      return length > 1 ? 'Consolidate' : 'Checkout'
    },
    errorWarehouse () {
      return !this.selected.every((v, i, arr) => v.Warehouse === arr[0].Warehouse)
    }
  }
}
</script>
